import * as React from 'react'
import { Icon } from '@iconify/react'
import { useIntersectionObserver } from '@uidotdev/usehooks'
import ScrollContainer from 'react-indiana-drag-scroll'

type plan = {
  name: string
  carrier: string
  subtitle: string
  dataSize: string | null
  isUnlimited: boolean
  dataSize5g4g3g: string | null
  dataSize4g3g: string | null
  notes: string | null
  monthlyPrice: number
  isMonthlyPriceMore?: boolean
  freeCallMin: number
  aisWifi: boolean
  premiumOption: boolean
  berryFreeCall: boolean
  domesticCallPrice: number
  smsPrice: number
  mmsPrice: number
  isRecommend: boolean
}

export const PlansMenu = (props: { data: plan[] }) => {
  return (
    <div className='flex flex-row gap-0'>
      <div className={`w-20 md:w-44 rounded-md flex flex-col pt-5 px-2 border-transparent py-4`}>
        <div className=''>&nbsp;</div>
        <div className='px-2 overflow-hidden flex flex-col rounded-xl mt-2 text-center h-[116px] bg-brand-purple-700 text-white py-2 font-semibold'>
          <img src={'/images/ais-logo.svg'} className='w-16 h-16 mx-auto' />
          <div className='text-sm'>スマホプラン</div>
        </div>
        <div className='text-[9px] md:text-[10px] w-20 md:w-44 pr-4'>
          <div className='h-[104px] text-right pt-7'>高速データ容量</div>
          <div className='h-[64px] text-right pt-5'>月額基本料金</div>
          <div className='h-[64px] text-right pt-6'>無料通話</div>
          <div className='h-[40px] text-right pt-2'>AIS Wi-Fi</div>
          <div className='h-[40px] text-right pt-2 leading-3'>
            プレミアム
            <br />
            オプション
          </div>
          <div className='h-[40px] text-right pt-2 leading-3'>
            ベリーAIS
            <br />
            間通話
          </div>
          <div className='h-[40px] text-right'></div>
          <div className='h-[44px] text-right pt-2'>国内通話料金</div>
          <div className='h-[44px] text-right pt-2'>SMS送信料金</div>
          <div className='h-[44px] text-right pt-2'>MMS送信料金</div>
        </div>
      </div>
      <ScrollContainer className="scroll-container">
        <div className='flex w-max py-4'>
          {props.data.map((plan, i) => (
            <div
              key={plan.name}
              className={`w-72 shrink-0 rounded-md flex flex-col relative ${
                plan.isRecommend ? 'border-4 border-red-500' : 'border-4 border-transparent'
              }`}
            >
              {plan.isRecommend ? (
                <div className='bg-red-500 text-white w-fit px-4 rounded-br-md font-bold'>RECOMMEND</div>
              ) : (
                <div className=''>&nbsp;</div>
              )}
              <div className='overflow-hidden flex flex-col rounded-xl m-2 text-center'>
                <div className='bg-brand-purple-700 text-white text-lg px-4 py-2 font-semibold'>
                  <div>{plan.name}</div>
                  <img src={'/images/ais-logo.svg'} className='w-10 h-10 mx-auto' />
                </div>
                <div className='bg-brand-purple-400 text-white text-xs px-4 py-2'>{plan.subtitle}</div>
                {plan.isUnlimited ? (
                  <div className='pt-6 grid grid-cols-5 bg-brand-purple-100 text-xs px-4 py-2 items-end justify-between'>
                    <div className=''>
                      <Icon icon='fluent:cellular-5g-20-filled' className='text-slate-500 h-9 w-9' />
                    </div>
                    <div className='font-bold col-span-4'>
                      <span className='text-4xl'>Unlimited</span>
                    </div>
                  </div>
                ) : (
                  <div className='grid grid-cols-3 bg-brand-purple-100 text-xs px-4 py-2 items-end justify-between'>
                    <div className=''>
                      <Icon icon='fluent:cellular-5g-20-filled' className='text-slate-500 h-9 w-9' />
                    </div>
                    <div className='font-bold text-md'>
                      <span className='text-5xl'>{plan.dataSize}</span>GB
                    </div>
                    <div className='flex flex-col text-right'>
                      <div className='text-[8px]'>
                        5G/4G/3G <span className='text-lg'>{plan.dataSize5g4g3g}</span>GB
                      </div>
                      <div className='text-[8px]'>
                        4G/3G <span className='text-lg'>{plan.dataSize4g3g}</span>GB
                      </div>
                    </div>
                  </div>
                )}
                <div className='whitespace-break-spaces bg-brand-purple-100 text-[10px] leading-3 px-4 h-8'>{plan.notes}</div>
                <div className='font-bold bg-slate-100 px-4 py-2'>
                  <span className='text-5xl'>{plan.monthlyPrice.toLocaleString()}</span>
                  <span className=''>THB</span>
                  {plan.isMonthlyPriceMore && <span className='text-2xl'>～</span>}
                </div>
                <div className='font-bold bg-brand-purple-100 px-4 py-2'>
                  <span className='text-5xl'>{plan.freeCallMin.toLocaleString()}</span>
                  <span className=''>min</span>
                </div>
                <div className='font-bold bg-slate-100 px-4 py-2'>
                  <span className=''>{plan.aisWifi ? '無制限で利用可能' : '-'}</span>
                </div>
                <div className='font-bold bg-brand-purple-100 px-4 py-2'>
                  <span className=''>{plan.premiumOption ? 'プレミアムオプション無料付帯' : '加入不可'}</span>
                </div>
                <div className='font-bold bg-slate-100 px-4 py-2'>
                  <span className=''>{plan.berryFreeCall ? 'タイ国内 24 時間無料' : '-'}</span>
                </div>
                <div className='font-bold text-white bg-brand-purple-400 px-4 py-2'>
                  <span className=''>プラン詳細</span>
                </div>
                <div className='bg-slate-100 px-4 py-2'>
                  <span className='text-lg'>{plan.domesticCallPrice} </span>THB/min
                </div>
                <div className='bg-brand-purple-100 px-4 py-2'>
                  <span className='text-lg'>{plan.smsPrice} </span>THB/ 通
                </div>
                <div className='bg-slate-100 px-4 py-2'>
                  <span className='text-lg'>{plan.mmsPrice} </span>THB/ 通
                </div>
              </div>
            </div>
          ))}
        </div>
      </ScrollContainer>
    </div>
  )
}
